.head-content[data-v-3a6dddf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.head-content .head-text[data-v-3a6dddf4] {
  font-size: 22px;
  color: #3d3d3d;
  padding: 5px 0 5px 30px;
}
.head-content .item-content[data-v-3a6dddf4] {
  padding: 30px;
  border: 1px solid #c0ccda;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fbfdff;
}
.head-content .item-content .item-right[data-v-3a6dddf4] {
  margin-left: 65px;
  font-size: 16px;
  color: #3d3d3d;
}
.head-content .item-content .item-right .right-text[data-v-3a6dddf4] {
  font-size: 72px;
  color: #3d3d3d;
}
.head-content .item-content .item-person[data-v-3a6dddf4] {
  margin-left: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-height: 150px;
  overflow-y: auto;
}
.head-content .item-content .item-person .person[data-v-3a6dddf4] {
  padding: 4px 10px;
  border-radius: 20px;
  background: #D8D8D8;
  margin: 0 24px 24px 0;
}
.title[data-v-3a6dddf4] {
  width: 70%;
  text-align: left;
  height: 50px;
  line-height: 50px;
  color: #333333;
  font-size: 28px;
  margin-left: 30%;
  padding: 12px 0;
}
.qualified[data-v-3a6dddf4] {
  width: 70%;
  text-align: left;
  height: 50px;
  line-height: 50px;
  color: #333333;
  font-size: 34px;
  font-weight: bold;
  margin-left: 30%;
  padding: 12px 0;
}
.pictureBox[data-v-3a6dddf4] {
  padding: 12px;
  background-color: #ffffff;
}
.pictureBox .head-text[data-v-3a6dddf4] {
  font-size: 22px;
  color: #3d3d3d;
  padding: 5px 0 5px 30px;
}