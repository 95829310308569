.container[data-v-059db209] {
  width: calc(100% - 24px);
  padding: 0 12px 32px 12px;
  min-height: calc(100% - 32px);
  background-color: #FFFFFF;
  position: relative;
}
.trainLeft[data-v-059db209] {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;
}
.trainLeft .trainStep[data-v-059db209] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 12px;
}
.trainLeft .trainStep .el-steps[data-v-059db209] {
  height: 300px;
}
.trainLeft .trainStep .el-steps[data-v-059db209] .el-step__title {
  cursor: pointer;
}
.trainLeft[data-v-059db209] .head-layout .headBtn {
  opacity: 0;
}
.trainLeft[data-v-059db209] .head-layout .headBtn button {
  cursor: default;
}
.content[data-v-059db209] {
  width: 100%;
}
.bottomBtn[data-v-059db209] {
  width: 100%;
  margin-top: 86px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 12px;
}